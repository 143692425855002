import React, { StrictMode } from "react";
import SettingsProvider from "./contexts/SettingsContext";
import TitleContextProvider from "./contexts/TitleContext";
import "nprogress/nprogress.css";
import ReactDOM from "react-dom";
import "react-image-lightbox/style.css";
import { BrowserRouter } from "react-router-dom";
import "simplebar/dist/simplebar.min.css";
import App from "./App";
import { BackdropProvider } from "./contexts/BackdropContext";

ReactDOM.render(
  <StrictMode>
      <SettingsProvider>
        <TitleContextProvider>
          <BackdropProvider>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </BackdropProvider>
        </TitleContextProvider>
      </SettingsProvider>
  </StrictMode>,
  document.getElementById("root")
);
