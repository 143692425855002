import React, { useEffect } from "react";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { StyledEngineProvider } from "@mui/material/styles";
import { Toaster } from "react-hot-toast";
import { useRoutes } from "react-router-dom";
import routes from "./routes";
import { ukoTheme } from "./theme";
import "./App.css";
import "react-datepicker/dist/react-datepicker.css";

const App = () => {
  const allPages = useRoutes(routes);

  const requestNotificationPermission = () => {
    const isIosDevice =
      /iPhone|iPad|iPod/.test(navigator.userAgent) && !window.MSStream;
    if (isIosDevice) {
      // Custom in-app notification logic for iOS devices
      console.log("iOS device detected; using in-app notifications instead.");
    } else {
      if (Notification.permission === "default") {
        Notification.requestPermission().then((permission) => {
          if (permission === "granted") {
            console.log("Notification permission granted.");
          } else {
            console.error("Notification permission denied.");
          }
        });
      }
    }
  };
  useEffect(() => {
    requestNotificationPermission();
  }, []);

  const appTheme = ukoTheme();

  const toasterOptions = {
    style: {
      fontWeight: 500,
      fontFamily: "'Montserrat', sans-serif",
    },
  };

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={appTheme}>
        <CssBaseline />
        <Toaster toastOptions={toasterOptions} />
        {allPages}
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
