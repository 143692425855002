import React, { useEffect, useState, useContext } from "react";
import { Box, CircularProgress, Backdrop, Typography } from "@mui/material";

import useTitle from "../../hooks/useTitle";
import "../../CommonCss/Button.css";
import { notifySuccess } from "../../utils/common.service";
import CryptoJS from "crypto-js";

import "./UserList.css";
import { BackdropContext } from "../../contexts/BackdropContext";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import ChatComponent from "./ChatComponent";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate, useParams } from "react-router-dom";
import "./chat.css";
import { BASE_URL } from "../../utils/constant";
import axios from "axios";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const Messages = () => {
  useTitle("Chat Messages");
  const [value, setValue] = React.useState(0);
  const navigate = useNavigate();

  const { backdropOpen, setBackdropOpen } = useContext(BackdropContext);
  const [userList, setUserList] = useState([]);
  const [websiteData, setWebsiteData] = useState({});
  const { domainID } = useParams();
  useEffect(() => {
    document.title = "Chat Messages";

    const encryptedToken = localStorage.getItem("admin-token");
    if (
      encryptedToken &&
      CryptoJS.AES.decrypt(
        encryptedToken,
        process.env.REACT_APP_SECRET_KEY
      ).toString(CryptoJS.enc.Utf8) === "pixerfect-chatbot-1010"
    ) {
      fetchData();
      fetchWebSiteData();
    } else {
      navigate("/login");
    }
    if (domainID) resetNotify();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {}, [userList]);

  const resetNotify = async () => {
    await axios.get(`${BASE_URL}api/web/notify/${domainID}`);
  };
  const resetNotifyForUser = async (id) => {
    await axios.get(`${BASE_URL}api/user/notify-admin/${id}`);
  };
  const updateUserUnseenCount = (userId, shouldDelete = false) => {
    setUserList((prevUserList) =>
      prevUserList.map((user) => {
        if (user._id === userId) {
          if (shouldDelete) {
            const { isUnseenByAdmin, ...userWithoutUnseen } = user;
            return userWithoutUnseen;
          } else {
            return {
              ...user,
              isUnseenByAdmin: user.hasOwnProperty("isUnseenByAdmin")
                ? user.isUnseenByAdmin + 1
                : 1,
            };
          }
        }
        return user;
      })
    );
  };
  const fetchData = async (obj) => {
    setBackdropOpen(true);
    fetch(`${BASE_URL}api/user/${domainID}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok " + response.statusText);
        }
        return response.json(); // Parse the JSON from the response
      })
      .then((res) => {
        setBackdropOpen(false);
        setUserList(res?.users || []);
        if (res?.users.length > 0) {
          updateUserUnseenCount(res?.users[0]._id, true);
        }
        notifySuccess(res.message);
      })
      .catch((error) => {
        setBackdropOpen(false);
        console.error("There was a problem with the fetch operation:", error);
      });
  };

  const fetchWebSiteData = async () => {
    fetch(`${BASE_URL}api/web/${domainID}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok " + response.statusText);
        }
        return response.json(); // Parse the JSON from the response
      })
      .then((res) => {
        setWebsiteData(res?.website || {});
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  };

  const handleChange = (event, newValue) => {
    const userId = event.target.getAttribute("user");
    setValue(newValue);
    updateUserUnseenCount(userId, true);
    resetNotifyForUser(userId);
  };

  const mobileUserListHandler = (e) => {
    let index = e.target.id;
    if (e.target.id.includes("vertical")) {
      index = e.target.id.match(/[^-]+$/)[0];
    }
    if (window.innerWidth <= 576 && e.target.id) {
      setTimeout(() => {
        document.querySelector(".tabbing-div").style.display = "none";
        document.querySelector(`#vertical-tabpanel-${index}`).style.display =
          "block";
      }, 100);
    }
  };
  return (
    <>
      {backdropOpen ? (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={backdropOpen}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <>
          <Typography
            className="back-to-dash"
            onClick={async () => {
              resetNotify();
              navigate("/dashboard");
            }}
          >
            <ArrowBackIcon />
            Back to Dashboard
          </Typography>

          <Box
            sx={{
              flexGrow: 1,
              bgcolor: "background.paper",
              display: "flex",
              height: "100%",
              borderTop: "1px solid #ccc",
            }}
          >
            <Tabs
              orientation="vertical"
              variant="scrollable"
              value={value}
              onChange={handleChange}
              onClick={mobileUserListHandler}
              aria-label="Vertical tabs example"
              className="tabbing-div"
              sx={{ borderRight: 1, borderColor: "divider", width: "276px" }}
            >
              {userList.length > 0 &&
                userList.map((item, index) => (
                  <Tab
                    label={
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                        user={item._id}
                        id={index}
                      >
                        {item?.name}
                        {item.isUnseenByAdmin > 0 && (
                          <span className="notify-dot">
                            {item.isUnseenByAdmin}
                          </span>
                        )}
                      </div>
                    }
                    {...a11yProps(index)}
                  />
                ))}
            </Tabs>
            {userList.length > 0 &&
              userList.map((item, index) => (
                <TabPanel value={value} index={index}>
                  <ChatComponent
                    userData={item}
                    webId={domainID}
                    webAgentName={websiteData.agentName}
                    webLogo={websiteData.logo}
                    updateUserUnseenCount={updateUserUnseenCount}
                    fetchData={fetchData}
                    setValue={setValue}
                    userList={userList}
                  />
                </TabPanel>
              ))}
          </Box>
        </>
      )}
    </>
  );
};

export default Messages;
