import { useEffect, useState } from "react";
import { LoadingButton } from "@mui/lab";
import { Box, Card } from "@mui/material";
import FlexBox from "../../components/FlexBox";
import LightTextField from "../../components/LightTextField";
import { H1, Paragraph } from "../../components/Typography";
import { TextFieldWrapper } from "../../components/authentication/StyledComponents";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import logo from "../../Image/logo/ChatLogo.jpg";
import "../../CommonCss/Button.css";
import { MESSAGE } from "../../utils/constant.jsx";
import CryptoJS from "crypto-js";

const Login = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Pixerfect ChatBot Dashboard";

    const encryptedToken = localStorage.getItem("admin-token");
    if (
      encryptedToken &&
      CryptoJS.AES.decrypt(
        encryptedToken,
        process.env.REACT_APP_SECRET_KEY
      ).toString(CryptoJS.enc.Utf8) === "pixerfect-chatbot-1010"
    ) {
      navigate("/dashboard");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initialValues = {
    email: "",
    password: "",
    submit: null,
    remember: true,
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email(MESSAGE.EMAIL_VALID)
      .max(255)
      .required(MESSAGE.EMAIL_REQUIRED),
    password: Yup.string()
      .min(6, MESSAGE.PASSWORD_MINIMUM)
      .required(MESSAGE.PASSWORD_REQUIRED),
  });

  const { errors, values, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues,
      validationSchema,
      onSubmit: async (values) => {
        try {
          setLoading(true);
          if (
            values.email === process.env.REACT_APP_ADMIN_USER &&
            values.password === process.env.REACT_APP_ADMIN_PASS
          ) {
            const token = `pixerfect-chatbot-1010`;

            // Encrypt the token before storing it in localStorage
            const encryptedToken = CryptoJS.AES.encrypt(
              token,
              process.env.REACT_APP_SECRET_KEY
            ).toString();

            localStorage.setItem("admin-token", encryptedToken);
            navigate("/dashboard");
          }
          setLoading(false);
        } catch (error) {
          setLoading(false);
        }
      },
    });

  return (
    <FlexBox
      sx={{
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
        height: { sm: "100%" },
      }}
    >
      <Card sx={{ padding: 4, maxWidth: 600, boxShadow: 1 }}>
        <FlexBox
          alignItems="center"
          flexDirection="column"
          justifyContent="center"
          mb={5}
        >
          <Box width={100} mb={1}>
            <img src={logo} width="100%" alt=" Logo" />
          </Box>
          <H1 fontSize={24} fontWeight={700}>
            Login to Pixerfect Chatbot Support Admin
          </H1>
        </FlexBox>

        <FlexBox justifyContent="space-between" flexWrap="wrap" my="1rem">
          <form noValidate onSubmit={handleSubmit} style={{ width: "100%" }}>
            <FlexBox justifyContent="space-between" flexWrap="wrap">
              <TextFieldWrapper sx={{ mt: 2, width: "100%" }}>
                <Paragraph fontWeight={600} mb={1}>
                  Email
                </Paragraph>
                <LightTextField
                  fullWidth
                  name="email"
                  type="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.email || ""}
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email}
                />
              </TextFieldWrapper>

              <TextFieldWrapper sx={{ mt: 2, width: "100%" }}>
                <Paragraph fontWeight={600} mb={1}>
                  Password
                </Paragraph>
                <LightTextField
                  fullWidth
                  name="password"
                  type="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.password || ""}
                  error={Boolean(touched.password && errors.password)}
                  helperText={touched.password && errors.password}
                />
              </TextFieldWrapper>
            </FlexBox>

            <Box sx={{ mt: 4 }}>
              <LoadingButton
                type="submit"
                loading={loading}
                loadingPosition="start"
                variant="contained"
                fullWidth
                className="button"
              >
                Log In
              </LoadingButton>
            </Box>
          </form>
        </FlexBox>
      </Card>
    </FlexBox>
  );
};

export default Login;
