import React, { useState } from "react";
import { Button, Grid, IconButton, Typography } from "@mui/material";
import LightTextField from "../../components/LightTextField";
import { useFormik } from "formik";
import * as Yup from "yup";
import { LoadingButton } from "@mui/lab";
import "../../CommonCss/Button.css";
import { notifyError, notifySuccess } from "../../utils/common.service";
import { BootstrapDialogTitle } from "../../components/Layouts/Modal/CustomizationModal";
import CloseIcon from "@mui/icons-material/Close";
import "../../CommonCss/Button.css";
import axios from "axios";
import { BASE_URL } from "../../utils/constant";

const UserForm = (props) => {
  const { editData, closeModal, id, fetchData } = props;

  const [buttonLoading, setButtonLoading] = useState(false);
  const [imageFile, setImageFile] = useState([]);
  const [removeButton, setRemoveButton] = useState(false);

  const initialValues = {
    domain: editData?.domain || "",
    webName: editData?.webName || "",
    initialMessage: editData?.initialMessage || "",
    agentName: editData?.agentName || "",
  };

  const validationSchema = Yup.object().shape({
    domain: Yup.string().required("Domain name is required"),
    webName: Yup.string().required("website heading is required"),
    initialMessage: Yup.string().required(
      "please provide your initial message"
    ),
    agentName: Yup.string().required("Agent name is required"),
  });

  const { errors, values, touched, handleChange, handleSubmit, resetForm } =
    useFormik({
      initialValues,
      validationSchema,
      onSubmit: async () => {
        try {
          // Set loading state for button
          setButtonLoading(true);

          // Create a new FormData object
          const formData = new FormData();

          imageFile[0]
            ? formData.append("logo", imageFile[0] || "")
            : formData.append("logo", editData.logo || "");
          formData.append("domain", values?.domain || "");
          formData.append("webName", values?.webName || "");
          formData.append("initialMessage", values?.initialMessage || "");
          formData.append("agentName", values?.agentName || "");
          formData.append("theme", editData?.theme || "");

          // Send POST request to the API
          const response = await axios.put(
            `${BASE_URL}api/web/${id}`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );

          if (response.status === 201) {
            setButtonLoading(false);
            // Handle success (e.g., show a success message, reset form, etc.)
            notifySuccess("Form submitted successfully!");
            resetForm(); // Resets the fo
            closeModal();
            fetchData();
          }
        } catch (err) {
          setButtonLoading(false);
          notifyError("Website with this domain already there!");
        }
      },
    });

  const handleChangeImage = (e) => {
    setRemoveButton(true);

    setImageFile([...imageFile, e.target.files[0]]);
  };

  const handleRemoveImage = () => {
    setImageFile("");
    setRemoveButton(false);
  };
  console.log("ERditDta", editData);

  return (
    <>
      <form onSubmit={handleSubmit}>
        <BootstrapDialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "-20px",
            marginLeft: "-8px",
            marginBottom: "-10px",
          }}
        >
          <h3>Edit Website</h3>

          <IconButton>
            <CloseIcon onClick={closeModal} />
          </IconButton>
        </BootstrapDialogTitle>

        <Grid container rowSpacing={{ xs: 3 }} columnSpacing={{ xs: 1 }}>
          <Grid item xs={4}>
            <Grid sx={{ textAlign: "center" }}>
              {editData.logo && editData.logo !== "" && (
                <img
                  src={editData.logo}
                  alt="CategoryPoster"
                  className="profileImg"
                />
              )}
            </Grid>
            <Grid
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              {imageFile.length !== 0 ? (
                <Typography sx={{ fontSize: "13px", fontWeight: "600", mb: 1 }}>
                  {imageFile[0].name || []}
                </Typography>
              ) : null}
              {removeButton && (
                <Button
                  variant="outlined"
                  onClick={handleRemoveImage}
                  sx={{
                    height: "10px",
                    color: "black",
                    borderColor: "black",
                  }}
                >
                  Remove
                </Button>
              )}
            </Grid>

            <Grid class="input--file">
              <Typography
                sx={{
                  fontWeight: 500,
                  color: "#2499EF",
                  cursor: "pointer",
                }}
              >
                Edit Profile
              </Typography>
              <input
                name="Select File"
                type="file"
                onChange={handleChangeImage}
              />
            </Grid>
          </Grid>

          <Grid item sm={4} xs={8}>
            <Typography
              as="h5"
              sx={{
                fontWeight: "500",
                fontSize: "14px",
                mb: "12px",
              }}
            >
              Heading
            </Typography>
            <LightTextField
              fullWidth
              name="webName"
              type="text"
              placeholder="Website title"
              value={values.webName}
              onChange={handleChange}
              error={Boolean(touched.webName && errors.webName)}
              helperText={touched.webName && errors.webName}
            />
          </Grid>

          <Grid item sm={4} xs={8}>
            <Typography
              as="h5"
              sx={{
                fontWeight: "500",
                fontSize: "14px",
                mb: "12px",
              }}
            >
              Domain
            </Typography>
            <LightTextField
              fullWidth
              name="domain"
              type="text"
              placeholder="Enter your domain"
              value={values.domain}
              onChange={handleChange}
              error={Boolean(touched.domain && errors.domain)}
              helperText={touched.domain && errors.domain}
              sx={{ cursor: "not-allowed" }}
              disabled={true}
            />
          </Grid>

          <Grid item sm={6} xs={8}>
            <Typography
              as="h5"
              sx={{
                fontWeight: "500",
                fontSize: "14px",
                mb: "12px",
              }}
            >
              Agent Name
            </Typography>
            <LightTextField
              fullWidth
              name="agentName"
              type="text"
              placeholder="Enter you agent name"
              value={values.agentName}
              onChange={handleChange}
              error={Boolean(touched.agentName && errors.agentName)}
              helperText={touched.agentName && errors.agentName}
            />
          </Grid>

          <Grid item sm={6} xs={8}>
            <Typography
              as="h5"
              sx={{
                fontWeight: "500",
                fontSize: "14px",
                mb: "12px",
              }}
            >
              Initial Message
            </Typography>
            <LightTextField
              fullWidth
              name="initialMessage"
              type="text"
              placeholder="Enter initial message"
              value={values.initialMessage}
              onChange={handleChange}
              error={Boolean(touched.initialMessage && errors.initialMessage)}
              helperText={touched.initialMessage && errors.initialMessage}
            />
          </Grid>

          <Grid item>
            <LoadingButton
              type="submit"
              loading={buttonLoading}
              loadingPosition="start"
              variant="contained"
              fullWidth
              className="button"
            >
              Update Website
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default UserForm;
