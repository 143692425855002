/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext, useEffect, useState } from "react";
import { Grid, CircularProgress, Backdrop } from "@mui/material";
import useTitle from "../../hooks/useTitle";
import { BackdropContext } from "../../contexts/BackdropContext";
import { BASE_URL, PAGE_CONSTANT } from "../../utils/constant";
import {
  isObjectEmpty,
  notifySuccess,
  showNotification,
} from "../../utils/common.service";
import "./SaasStyle.css";
import { useNavigate } from "react-router-dom";
import CryptoJS from "crypto-js";
import { io } from "socket.io-client";

const SaaS = () => {
  useTitle("Dashboard");
  const [websData, setWebsData] = useState([]);
  const [isSettled, setIsSettled] = useState(false);
  const navigate = useNavigate();
  const fetchData = async (obj) => {
    setBackdropOpen(true);
    fetch(`${BASE_URL}api/web`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok " + response.statusText);
        }
        return response.json(); // Parse the JSON from the response
      })
      .then((res) => {
        setBackdropOpen(false);
        setWebsData(res?.websites || []);

        notifySuccess(res.message);
      })
      .catch((error) => {
        setBackdropOpen(false);
        console.error("There was a problem with the fetch operation:", error);
      });
  };

  const { backdropOpen, setBackdropOpen } = useContext(BackdropContext);

  useEffect(() => {
    document.title = PAGE_CONSTANT.DASHBOARD;
    const encryptedToken = localStorage.getItem("admin-token");
    if (
      encryptedToken &&
      CryptoJS.AES.decrypt(
        encryptedToken,
        process.env.REACT_APP_SECRET_KEY
      ).toString(CryptoJS.enc.Utf8) === "pixerfect-chatbot-1010"
    ) {
      fetchData();
    } else {
      navigate("/login");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const cardClickHandler = (id) => {
    updateWebUnseenCount(id, true);
    navigate(`/messages/${id}`);
  };

  useEffect(() => {
    if (websData.length > 0) {
      if (!isSettled) {
        const socket = io(BASE_URL, {
          transports: ["websocket"],
          cors: {
            origin: "*",
          },
        });
        socket.emit("adminOnline");
        // Listen for new message notifications
        socket.on("newMessage", (msg) => {
          const singleWebData = websData?.find((x) => x._id === msg.webId);
          if (!isObjectEmpty(singleWebData)) {
            showNotification(
              singleWebData.webName,
              {
                body: msg.message,
                icon: singleWebData.logo,
              },
              window.location.origin + "/messages/" + msg.from
            );
            updateWebUnseenCount(msg.webId);
          }
        });
        setIsSettled(true);
      }
    }
  }, [websData]);

  const updateWebUnseenCount = (webId, shouldDelete = false) => {
    setWebsData((prevWebList) =>
      prevWebList.map((web) => {
        if (web._id === webId) {
          if (shouldDelete) {
            const { isUnseen, ...userWithoutUnseen } = web;

            return userWithoutUnseen;
          } else {
            return {
              ...web,
              isUnseen: web.hasOwnProperty("isUnseen") ? web.isUnseen + 1 : 1,
            };
          }
        }
        return web;
      })
    );
  };

  return (
    <Grid container spacing={4} pt={4}>
      {backdropOpen ? (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={backdropOpen}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <>
          <Grid className="website-div">
            {websData.map((item) => (
              <Grid
                className="website-card"
                onClick={() => cardClickHandler(item?._id)}
              >
                {item.isUnseen > 0 && (
                  <span className="notify-dot">{item.isUnseen}</span>
                )}
                <Grid>
                  <img src={item?.logo || ""} alt="sitelogo" />
                  <h3>{item?.webName}</h3>
                </Grid>
                <p>
                  Domain : <a href="#">{item?.domain}</a>
                </p>
                <p>
                  Agent : <span>{item?.agentName}</span>
                </p>
              </Grid>
            ))}
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default SaaS;
