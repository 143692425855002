import React from "react";
import DashboardLayout from "./components/Layouts/DashboardLayout";
import { Navigate } from "react-router-dom";
import Login from "./pages/authentication/Login";
import DashboardSaaS from "./pages/dashboards/SaaS";
import Error from "./pages/404";
import UsersList from "./pages/users/Users";
import Messages from "./pages/users/Messages";


const routes = [
  {
    path: "/",
    element: <Navigate to="dashboard" />,
  },
  {
    path: "login",
    element: <Login />,
  },
  {
    path: "messages/:domainID",
    element: <Messages />,
  },
  {
    path: "dashboard",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <DashboardSaaS />,
      },
      {
        path: "users",
        element: <UsersList />,
      },
      
    ],
  },

  {
    path: "*",
    element: <Error />,
  },
];

export default routes;
