import React, { useEffect, useState } from "react";
import {
  Box,
  Drawer,
  List,
  ListItemButton,
  styled,
  useMediaQuery,
} from "@mui/material";
import ScrollBar from "simplebar-react";
import logo from "../../Image/logo/ChatLogo.jpg";

import SubMenu from "./DashboardSub.jsx";
import Icons from "../../icons/sidebar";

const MainMenu = styled(Box)(({ theme }) => ({
  left: 0,
  width: 230,
  height: "100%",
  position: "fixed",
  boxShadow: theme.shadows[2],
  transition: "left 0.5s ease",
  zIndex: theme.zIndex.drawer + 11,
  backgroundColor: theme.palette.background.paper,
  [theme.breakpoints.down("md")]: { left: -80 },
  "& .simplebar-track.simplebar-vertical": { width: 7 },
  "& .simplebar-scrollbar:before": {
    background: theme.palette.text.primary,
  },
}));

const StyledListItemButton = styled(ListItemButton)(() => ({
  marginBottom: "1rem",
  justifyContent: "center",
  "&:hover": { backgroundColor: "transparent" },
}));

const DashboardSideBar = ({ showMobileSideBar, closeMobileSideBar }) => {

  const [sideBarData, setSideBarData] = useState(["Dashboard"]);

  const data = [
    "Dashboard",
    "Website",
    "Messages"
  ];

  useEffect(() => {   
        setRoleAccess(data);   
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setRoleAccess = () => {
    const SidebarData = [
      {
        title: "Dashboard",
        Icon: Icons.DashboardIcon,
        path: "/dashboard",
        hidden: !data.includes("Dashboard"),
      },

      {
        title: "Websites",
        Icon: Icons.ManageAccountsOutlined,
        path: "/dashboard/users",
        hidden: !data.includes("Website"),
      },

      // {
      //   title: "Messages",
      //   Icon: Icons.SupportAgent,
      //   path: "/messages",
      //   hidden: !data.includes("Messages"),
      // },
    ];

    const filteredSidebarData = SidebarData.filter((item) => !item.hidden);
    setSideBarData(filteredSidebarData);
  };

  const downMd = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const mainSideBarContent = (
    <List sx={{ height: "100%" }} onClick={closeMobileSideBar}>
      <StyledListItemButton disableRipple>
        <img src={logo} alt="IO" width={130} />
      </StyledListItemButton>
      <ScrollBar style={{ maxHeight: "calc(100% - 50px)" }}>
        {sideBarData.map((nav, index) => {
          return (
            <React.Fragment key={index}>
              <SubMenu nav={nav} key={index} />
            </React.Fragment>
          );
        })}
      </ScrollBar>
    </List>
  );

  if (downMd) {
    return (
      <Drawer
        anchor="left"
        open={showMobileSideBar}
        onClose={closeMobileSideBar}
        PaperProps={{ sx: { width: "230px" } }}
      >
        <Box
          sx={{
            height: "100%",
            display: "flex",
            width: "230px",
            position: "fixed",
            flexDirection: "column",
            boxShadow: (theme) => theme.shadows[1],
            backgroundColor: (theme) => theme.palette.background.paper,
            "& .simplebar-track.simplebar-vertical": { width: 7 },
            "& .simplebar-scrollbar:before": {
              background: (theme) => theme.palette.text.primary,
            },
          }}
        >
          {mainSideBarContent}
        </Box>
      </Drawer>
    );
  }

  return <MainMenu>{mainSideBarContent}</MainMenu>;
};

export default DashboardSideBar;
