import React, { useEffect, useState, useRef } from "react";
import {
  Backdrop,
  CircularProgress,
  Grid,
  Typography,
  TextField,
  IconButton,
  Tooltip,
} from "@mui/material";
import io from "socket.io-client";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import SendIcon from "@mui/icons-material/Send";
import "./UserList.css";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import "./chat.css";
import {
  notifySuccess,
  showConfirmDialog,
  showNotification,
} from "../../utils/common.service";
import { BASE_URL, CONSTANT } from "../../utils/constant";

export const stringToColor = (string) => {
  let hash = 0;
  for (let i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }
  let color = "#";
  for (let i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  return color;
};

function stringAvatar(name) {
  const nameParts = name.split(" ");
  const initials =
    nameParts.length > 1
      ? `${nameParts[0][0]}${nameParts[1][0]}`
      : `${nameParts[0][0]}`;
  return {
    sx: { bgcolor: stringToColor(name) },
    children: initials.toUpperCase(),
  };
}

const ChatComponent = (props) => {
  const {
    userData,
    webLogo,
    webId,
    webAgentName,
    updateUserUnseenCount,
    fetchData,
    setValue,
    userList,
  } = props;
  const [oldChat, setOldChat] = useState([]);
  const [isCalled, setIsCalled] = useState(false);
  const [backdropOpen, setBackdropOpen] = useState(false);
  const [newMessage, setNewMessage] = useState("");
  const [socket, setSocket] = useState(null);
  const chatContainerRef = useRef(null);

  useEffect(() => {
    const socketInstance = io(BASE_URL, {
      query: { userId: userData?._id, webId: webId },
    });
    setSocket(socketInstance);

    socketInstance.emit("online", { userId: webId });

    socketInstance.on("receiveMessage", (data) => {
      const { message } = data;

      showNotification(
        userData.name,
        {
          body: message,
          icon: webLogo,
        },
        window.location.origin + "/messages/" + webId
      );

      if (
        !userList.some((user) => user._id === data.from) &&
        data.webId === webId
      ) {
        fetchData();
        setOldChat([]);
        setValue(0);
        fetchExistingChats();
      } else {
        if (data.from === userData._id && data.webId === webId) {
          const incomingMessage = {
            from: userData._id,
            to: webId,
            message,
            webId: userData._id,
          };
          setOldChat((prevChat) => [...prevChat, incomingMessage]);
        } else {
          updateUserUnseenCount(data.from);
        }
      }
    });

    if (!isCalled) {
      fetchExistingChats();
      setIsCalled(true);
    }

    return () => {
      socketInstance.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData?._id, webId]);

  const fetchExistingChats = async () => {
    setBackdropOpen(true);
    fetch(`${BASE_URL}api/message/${webId}/${userData._id}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok " + response.statusText);
        }
        return response.json();
      })
      .then((res) => {
        setIsCalled(true);
        setBackdropOpen(false);
        setOldChat(res.messages);
        notifySuccess("Messages fetched successfully");
      })
      .catch((error) => {
        setIsCalled(false);
        setBackdropOpen(false);
        console.error("There was a problem with the fetch operation:", error);
      });
  };

  const handleSendMessage = () => {
    if (newMessage.trim() && socket) {
      socket.emit("sendMessage", {
        to: userData._id,
        from: webId,
        message: newMessage,
        webId: webId,
      });
      setOldChat([
        ...oldChat,
        {
          to: userData._id,
          from: webId,
          message: newMessage,
          webId: webId,
        },
      ]);
      setNewMessage("");
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSendMessage();
    }
  };

  // Scroll to the bottom of the chat container when new messages are added
  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTo({
        top: chatContainerRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  }, [oldChat]);

  const handleDeleteUser = async (_id) => {
    showConfirmDialog(CONSTANT.DELETE_CONFIRMATION, async (confirmed) => {
      if (confirmed) {
        fetch(`${BASE_URL}api/user/${_id}`, {
          method: "DELETE", // Specify the DELETE method
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error(
                "Network response was not ok " + response.statusText
              );
            }
            return response.json(); // Parse the JSON from the response
          })
          .then((res) => {
            fetchData();
            setValue(0);
            notifySuccess(res.message);
            fetchExistingChats();
          })
          .catch((error) => {
            console.error(
              "There was a problem with the fetch operation:",
              error
            );
          });
      }
    });
  };

  const mobileUserListHandler = () => {
    document.querySelector(".tabbing-div").style.display = "flex";
    document.querySelectorAll('[role="tabpanel"]').forEach((ele) => {
      ele.style.display = "none";
    });
  };
  return (
    <Grid sx={{ py: 0 }}>
      <Grid className="user-heading">
        <ArrowBackIcon
          className="user-list-back-btn"
          onClick={() => mobileUserListHandler()}
        />
        <Stack direction="row" spacing={2}>
          <Avatar {...stringAvatar(userData?.name)} />
        </Stack>
        <Typography variant="h6">{userData?.name}</Typography>
        <Tooltip
          title="Delete"
          placement="top-start"
          sx={{
            cursor: "pointer",
            marginLeft: "10px",
            border: "1px solid",
            borderRadius: "5px",
            height: "30px",
            width: "30px",
            padding: "2px",
          }}
          onClick={(e) => {
            handleDeleteUser(userData._id);
          }}
        >
          <DeleteOutlineOutlinedIcon className="icons-delete" color="error" />
        </Tooltip>
      </Grid>

      {backdropOpen ? (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={backdropOpen}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <Grid className="message-container" ref={chatContainerRef}>
          {oldChat.length > 0 &&
            oldChat.map((item, index) => (
              <div
                key={index}
                className={item?.from === webId ? "sender" : "receiver"}
              >
                <div className="message-content">
                  {item?.from !== webId && (
                    <Stack direction="row" spacing={2}>
                      <Avatar {...stringAvatar(userData.name)} />
                    </Stack>
                  )}
                  <div className="message-text">
                    <h5 className="user-name">
                      {item?.from === userData._id
                        ? userData.name
                        : webAgentName
                        ? webAgentName
                        : "Admin"}
                    </h5>
                    <p>{item?.message}</p>
                  </div>
                  {item?.from === webId && (
                    <Stack direction="row" spacing={2}>
                      <Avatar
                        {...stringAvatar(webAgentName ? webAgentName : "Admin")}
                      />
                    </Stack>
                  )}
                </div>
              </div>
            ))}
        </Grid>
      )}

      <Grid alignItems="center" className="message-input-container">
        <TextField
          fullWidth
          placeholder="Type a message"
          variant="outlined"
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          onKeyPress={handleKeyPress}
          className="message-input"
        />
        <IconButton color="primary" onClick={handleSendMessage}>
          <SendIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default ChatComponent;
