import {
  Login as LoginIcon,
  PersonAdd as AddUserIcon,
  PeopleAltOutlined,
  ManageAccountsOutlined,
  SupportAgent,
  RateReviewOutlined,
  DynamicFeedOutlined,
  FoodBankOutlined,
  HistoryOutlined
} from "@mui/icons-material";
import DashboardIcon from "./Dashboard";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  DashboardIcon,
  DynamicFeedOutlined,
  ManageAccountsOutlined,
  PeopleAltOutlined,
  RateReviewOutlined,
  SupportAgent,
  LoginIcon,
  AddUserIcon,
  FoodBankOutlined,
  HistoryOutlined,
  AssessmentOutlinedIcon,
};
