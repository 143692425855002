import React, { useEffect, useState, useContext } from "react";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Grid,
  Tooltip,
  styled,
} from "@mui/material";
import FlexBox from "../../components/FlexBox";
import SearchInput from "../../components/SearchInput";
import useTitle from "../../hooks/useTitle";
import "../../CommonCss/Button.css";
import { notifySuccess, showConfirmDialog } from "../../utils/common.service";
import { BASE_URL, CONSTANT, PAGE_CONSTANT } from "../../utils/constant";
import CryptoJS from "crypto-js";

import { Small } from "../../components/Typography";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import DriveFileRenameOutlineRoundedIcon from "@mui/icons-material/DriveFileRenameOutlineRounded";
import "./UserList.css";
import NodataFound from "../../components/Layouts/NodataFound";
import UserTable from "../../components/table/CustomTable";
import { BackdropContext } from "../../contexts/BackdropContext";
import FilterMenu from "../../components/filter/filterMenu";
import moment from "moment";
import CustomizationDialog from "../../components/Layouts/Modal/CustomizationModal";
import UserForm from "./UserForm";
import AddNewUser from "../../userManagement/AddNewUser";
import CodeIcon from "@mui/icons-material/Code";
import { useNavigate } from "react-router-dom";

const StyledFlexBox = styled(FlexBox)(({ theme }) => ({
  justifyContent: "space-between",
  alignItems: "center",
  flexWrap: "wrap",
  marginBottom: 20,
  [theme.breakpoints.down(500)]: {
    width: "100%",
    "& .MuiInputBase-root": { maxWidth: "100%" },
    "& .MuiButton-root": {
      width: "100%",
      marginTop: 15,
    },
  },
}));

const UserList = () => {
  useTitle("Websites");

  const { backdropOpen, setBackdropOpen } = useContext(BackdropContext);

  const [pageObj, setPageObj] = useState({
    pageCount: 1,
    limit: 5,
    search: "",
    sortingField: "",
    sortingOrder: "desc",
  });
  const [websData, setWebsData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const navigate = useNavigate();
  const [userId, setUserId] = useState("");

  useEffect(() => {
    document.title = PAGE_CONSTANT.USER_LIST;
    const encryptedToken = localStorage.getItem("admin-token");
    if (
      encryptedToken &&
      CryptoJS.AES.decrypt(
        encryptedToken,
        process.env.REACT_APP_SECRET_KEY
      ).toString(CryptoJS.enc.Utf8) === "pixerfect-chatbot-1010"
    ) {
      fetchData();
    } else {
      navigate("/login");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const UserListColumnShape = [
    {
      Header: "Website Logo",
      accessor: "logo",
      minWidth: 120,
      Cell: ({ value }) => {
        return (
          <FlexBox alignItems="center">
            {value ? (
              <img src={value} alt="logo" className="Category-image" />
            ) : (
              "-"
            )}
          </FlexBox>
        );
      },
    },
    {
      Header: "Website ID",
      accessor: "_id",
      minWidth: 150,
      Cell: ({ value }) => (
        <Small
          sx={{
            color: "text.primary",
          }}
        >
          {value || "-"}
        </Small>
      ),
    },
    {
      Header: "Title",
      accessor: "webName",
      minWidth: 200,
      Cell: ({ value }) => (
        <Small
          sx={{
            color: "text.primary",
          }}
        >
          {value || "-"}
        </Small>
      ),
    },

    {
      Header: "Domain",
      accessor: "domain",
      minWidth: 150,
      Cell: ({ value }) => (
        <Small
          sx={{
            color: "text.primary",
          }}
        >
          {value || "-"}
        </Small>
      ),
    },
    {
      Header: "Agent",
      accessor: "agentName",
      minWidth: 150,
      Cell: ({ value }) => (
        <Small
          sx={{
            color: "text.primary",
          }}
        >
          {value || "-"}
        </Small>
      ),
    },

    {
      Header: "Created Date",
      accessor: "createdAt",
      minWidth: 140,
      Cell: ({ value }) => {
        return (
          <>
            <Small
              sx={{
                borderRadius: 10,
                color: "text.primary",
              }}
            >
              {moment
                .utc(value)
                .utcOffset("+05:30")
                .format(CONSTANT.DATE_FORMATE)}
            </Small>{" "}
            <Grid
              sx={{
                borderRadius: 10,
                color: "gray",
                mt: "2px",
              }}
            >
              {moment.utc(value).utcOffset("+05:30").format("hh:mm A")}
            </Grid>
          </>
        );
      },
    },

    {
      Header: "Action",
      accessor: "action",
      minWidth: 100,
      maxWidth: 100,
      Cell: ({ row }) => {
        const { _id } = row.original;
        return (
          <FlexBox sx={{ display: "flex", justifyContent: "right" }}>
            <Tooltip
              title="Copy script and place it within the <body> section of your index.html file."
              placement="top-start"
              sx={{
                cursor: "pointer",
                border: "1px solid",
                borderRadius: "5px",
                height: "30px",
                width: "30px",
              }}
              onClick={(e) => {
                copyToClipboard();
              }}
            >
              <CodeIcon className="icons-edit" color="primary" />
            </Tooltip>
            <Tooltip
              title="Edit"
              placement="top-start"
              sx={{
                cursor: "pointer",
                border: "1px solid",
                marginLeft: "10px",
                borderRadius: "5px",
                height: "30px",
                width: "30px",
                padding: "2px",
              }}
              onClick={(e) => {
                handleOpenModal(_id);
              }}
            >
              <DriveFileRenameOutlineRoundedIcon
                className="icons-edit"
                color="primary"
              />
            </Tooltip>

            <Tooltip
              title="Delete"
              placement="top-start"
              sx={{
                cursor: "pointer",
                marginLeft: "10px",
                border: "1px solid",
                borderRadius: "5px",
                height: "30px",
                width: "30px",
                padding: "2px",
              }}
              onClick={(e) => {
                handleDeleteUser(_id);
              }}
            >
              <DeleteOutlineOutlinedIcon
                className="icons-delete"
                color="error"
              />
            </Tooltip>
          </FlexBox>
        );
      },
    },
  ];

  function copyToClipboard() {
    const textToCopy = `<script src="https://support.pixerfect.com/chat-min-js.js" defer></script>`;
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        notifySuccess("Copied to clipboard!");
      })
      .catch((err) => {
        console.error("Failed to copy:", err);
      });
  }

  const fetchData = async (obj) => {
    setBackdropOpen(true);
    fetch(`${BASE_URL}api/web`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok " + response.statusText);
        }
        return response.json(); // Parse the JSON from the response
      })
      .then((res) => {
        setBackdropOpen(false);
        setWebsData(res?.websites || []);
        notifySuccess(res.message);
      })
      .catch((error) => {
        setBackdropOpen(false);
        console.error("There was a problem with the fetch operation:", error);
      });
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearch(event.target.value);
    }
  };

  const handleSearch = (value) => {
    setPageObj({ ...pageObj, search: value, pageCount: 1 });
  };

  const handleDeleteUser = async (_id) => {
    showConfirmDialog(CONSTANT.DELETE_CONFIRMATION, async (confirmed) => {
      if (confirmed) {
        fetch(`${BASE_URL}api/web/${_id}`, {
          method: "DELETE", // Specify the DELETE method
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error(
                "Network response was not ok " + response.statusText
              );
            }
            return response.json(); // Parse the JSON from the response
          })
          .then((res) => {
            fetchData();
            notifySuccess(res.message);
          })
          .catch((error) => {
            console.error(
              "There was a problem with the fetch operation:",
              error
            );
          });
      }
    });
  };

  const handleRequestSort = (event, property) => {
    const isAsc =
      pageObj["sortingField"] === property && pageObj["sortingOrder"] === "asc";
    const order = isAsc ? "desc" : "asc";
    setPageObj({
      ...pageObj,
      sortingField: property,
      sortingOrder: order,
    });
  };

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  const handleOpenModal = async (_id) => {
    if (_id) {
      setUserId(_id);
      setOpenModal(true);
    } else {
      setOpenModal(true);
    }
  };

  const handleCloseModal = () => {
    setUserId("");
    setOpenModal(false);
  };

  return (
    <Box pt={2} pb={4}>
      <Grid
        sx={{ display: "flex", justifyContent: "end" }}
        className="style-box"
      >
        <Button onClick={() => handleOpenModal()} className="button">
          Add New Web
        </Button>
      </Grid>

      <StyledFlexBox sx={{ mt: 2 }} className="style-box">
        <SearchInput
          sx={{ width: "inherit" }}
          placeholder="Search users..."
          onKeyPress={handleKeyPress}
        />
        <FilterMenu
          setPageObj={setPageObj}
          pageObj={pageObj}
          showSort={false}
          showStatus={false}
          showRangePicker={false}
          showExport={false}
        />
      </StyledFlexBox>

      <Grid>
        <UserTable
          isSort={true}
          pageObj={pageObj}
          columnShape={UserListColumnShape}
          data={websData}
          hidePagination={false}
          createSortHandler={createSortHandler}
        />
        {!backdropOpen &&
          (!websData || (websData.length <= 0 && <NodataFound />))}
      </Grid>

      {backdropOpen && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={backdropOpen}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}

      <CustomizationDialog
        open={openModal}
        handleClose={handleCloseModal}
        children={
          !userId ? (
            <AddNewUser closeForm={handleCloseModal} fetchData={fetchData} />
          ) : (
            <UserForm
              id={userId}
              closeModal={handleCloseModal}
              editData={websData.filter((x) => x._id === userId)[0]}
              fetchData={fetchData}
            />
          )
        }
      />
    </Box>
  );
};

export default UserList;
