import { Pagination, Paper, Switch, alpha, styled } from "@mui/material";
import toast from "react-hot-toast";
import swal from "sweetalert2";
import { CONSTANT } from "./constant";
import jwt_decode from "jwt-decode";
import { Box } from "@mui/system";
import FlexBox from "../components/FlexBox";

export const isArrayEmpty = (array) =>
  typeof array == CONSTANT.UNDEFINED || array == null || array.length <= 0;

export const notifyError = (message) => toast.error(message);

export const notifySuccess = (message) => toast.success(message);

export const StyledPagination = styled(Pagination)(({ theme }) => ({
  "& .MuiPaginationItem-root": {
    fontSize: 12,
    fontWeight: 500,
    color: theme.palette.text.disabled,
  },
  "& .MuiPaginationItem-page:hover": {
    borderRadius: 20,
    backgroundColor: "transparent",
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
  },
  "& .MuiPaginationItem-page.Mui-selected": {
    borderRadius: 20,
    backgroundColor: "transparent",
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
  },
  "& .MuiPaginationItem-previousNext": {
    margin: 10,
    borderRadius: 20,
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    "&:hover": { backgroundColor: "transparent" },
  },
}));

export const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export const sentenceCase = (string) => {
  if (string) {
    var sentence = string.toLowerCase().split(" ");
    for (var i = 0; i < sentence.length; i++) {
      sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
    }
    return sentence;
  }
  return string;
};

export const stringAvatar = (name) => {
  if (!name) return;
  return {
    sx: {
      fontSize: 12,
      height: "40px",
      width: "40px",
      mb: "10px",
      bgcolor: "#44564a",
      color: "white",
      fontWeight: "600",
    },
    children: `${sentenceCase(name.split()[0][0])}`,
  };
};

export const isObjectEmpty = (obj) => {
  return !obj || typeof obj === "undefined" || Object.keys(obj).length <= 0;
};
export const getPartnerName = (name) => {
  return name && name === CONSTANT.CARRYPARCEL
    ? CONSTANT.CARRY_PARCEL
    : CONSTANT.TRAVELER_PARTNER || "";
};

export const stringToColor = (string) => {
  let hash = 0;
  let i;

  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  return color;
};

export const ButtonWrapper = styled(Box)(({ theme }) => ({
  width: 100,
  height: 100,
  display: "flex",
  borderRadius: "50%",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor:
    theme.palette.mode === "light"
      ? theme.palette.secondary[200]
      : alpha(theme.palette.primary[100], 0.1),
}));

export const StatusSwitch = styled((props) => (
  <Switch
    focusVisibleClassName=".Mui-focusVisible"
    disableRipple
    {...props}
    sx={{ borderRadius: "12px" }}
  />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

export const returnError = (err) => {
  return (
    err?.response?.data?.message || err?.message || CONSTANT.SOMETHING_WRONG
  );
};

export const StyledFlexBox = styled(FlexBox)(({ theme }) => ({
  justifyContent: "space-between",
  alignItems: "center",
  flexWrap: "wrap",
  marginBottom: 20,
  [theme.breakpoints.down(500)]: {
    width: "100%",
    "& .MuiInputBase-root": { maxWidth: "100%" },
    "& .MuiButton-root": {
      width: "100%",
      marginTop: 15,
    },
  },
}));

export const showConfirmDialog = (message, callback) => {
  new swal({
    iconColor: "black",
    className: "alert",
    title: message,
    text: "You won't be able to revert this!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#44564a",
    cancelButtonColor: "white",
    confirmButtonText: "Yes, delete it!",
    width: "24rem",
    height: "18rem",
  }).then((result) => {
    if (result.value) {
      callback(result.value);
    } else {
      callback(false);
    }
  });
};

export const getPageSizeDeviceWise = () => {
  const width = window.innerWidth;
  if (width < "576px") {
    return 2;
  } else if (width >= "576px") {
    return 4;
  } else if (width >= "768px") {
    return 6;
  } else if (width >= "992px") {
    return 7;
  } else if (width >= "1200px") {
    return 8;
  } else if (width >= "1400px") {
    return 9;
  } else {
    return 10;
  }
};

export const getUserData = () => {
  const token = localStorage.getItem(btoa("token"));
  const decode = jwt_decode(token);
  return decode;
};

export const getCurrencySymbol = (currencyCode) => {
  try {
    const currencySymbol = new Intl.NumberFormat("en", {
      style: "currency",
      currency: currencyCode,
    })
      .formatToParts(0)
      .find((part) => part.type === "currency").value;

    return currencySymbol;
  } catch (error) {
    console.error(`Failed to get currency symbol for ${currencyCode}:`, error);
    return null;
  }
};

export const getChatImageUrl = (id, name) => {
  return `https://getstream.io/random_png/?id=${id}&name=${name}`;
};

export const showConfirmDialogWithBox = (obj, callback) => {
  new swal({
    iconColor: "black",
    className: "alert",
    title: obj.title,

    icon: "question",
    showCancelButton: true,
    confirmButtonColor: "black",
    cancelButtonColor: "yellow",
    confirmButtonText: "Proceed",
    width: "30rem",
    height: "18rem",
    html: `
    <style>
      .reason-note {
        font-size: 12px;
        color: #000;
      }
    </style>
    <div class="custom-popup">
    <h2 class="title">${obj.text}</h2>
    <h2 class="sub-title">${obj.value}</h2>
    <p class="reason">${obj.reason.join(", ")}</p>
    <p class="reason-note">${obj.reasonNote}</p>
    <input id="reason" class="swal2-input" placeholder="Please enter reason">
    </div>
  `,
    focusConfirm: false,
    preConfirm: () => {
      const reason = document.getElementById("reason").value;
      return { reason: reason };
    },
  }).then((result) => {
    if (result.isConfirmed) {
      if (result.value) {
        callback(result.value);
      } else {
        callback(false);
      }
    }
  });
};

export const getSubDividedArray = (array, count, callback) => {
  let recordArrays = [];

  for (let i = 0; i < array.length; i += count) {
    recordArrays.push(array.slice(i, i + count));
  }
  callback(recordArrays);
};

export function formatCount(count) {
  if (typeof count !== "number") {
    return "";
  }
  var formattedCount;
  if (count >= 1000000000000) {
    formattedCount = (count / 1000000000000).toFixed(2);
    if (formattedCount.endsWith(".00")) {
      return (count / 1000000000000).toFixed(0);
    } else {
      return formattedCount;
    }
  } else if (count >= 1000000000) {
    formattedCount = (count / 1000000000).toFixed(2);
    if (formattedCount.endsWith(".00")) {
      return (count / 1000000000).toFixed(0);
    } else {
      return formattedCount;
    }
  } else if (count >= 1000000) {
    formattedCount = (count / 1000000).toFixed(2);
    if (formattedCount.endsWith(".00")) {
      return (count / 1000000).toFixed(0);
    } else {
      return formattedCount;
    }
  } else if (count >= 1000) {
    formattedCount = (count / 1000).toFixed(2);
    if (formattedCount.endsWith(".00")) {
      return (count / 1000).toFixed(0);
    } else {
      return formattedCount;
    }
  } else {
    return count.toString();
  }
}

export function formatCountString(count) {
  return count >= 1000000000000
    ? "t"
    : count >= 1000000000
    ? "b"
    : count >= 1000000
    ? "m"
    : count >= 1000
    ? "k"
    : "";
}

export function percentageValue(amount, per) {
  return (amount / 100) * per;
}

export const capitalizeFirstLetter = (str, defaultValue = "-") => {
  if (!str) {
    return defaultValue;
  }
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const deleteStatus = (res) => {
  const integerKeys = Object.keys(res).filter((key) =>
    Number.isInteger(Number(key))
  );
  return integerKeys.map((a) => res[a]);
};

export function timeDifference(dateString1, dateString2) {
  const date1 = new Date(dateString1);
  const date2 = new Date(dateString2);

  const difference = Math.abs(date2 - date1); // Get the difference in milliseconds

  const minutes = Math.floor(difference / 60000); // Convert milliseconds to minutes
  const seconds = Math.floor((difference % 60000) / 1000); // Convert remainder to seconds

  return { minutes, seconds }; // Returning an object with minutes and seconds
}

export function convertToUTCString(timeString) {
  // Parse the given time string
  if (timeString) {
    const [time, date] = timeString.split(" ");
    const [hours, minutes, seconds] = time.split(":");
    const [dd, mm, yyyy] = date.split("-");

    // Create a new date in UTC
    const utcDate = new Date(
      `${mm}/${dd}/${yyyy} ${hours}:${minutes}:${seconds} UTC`
    );

    // Convert to IST (UTC+5:30)
    utcDate.setHours(utcDate.getHours() + 5); // Adding 5 hours for IST
    utcDate.setMinutes(utcDate.getMinutes() + 30); // Adding 30 minutes for IST

    // Format the date to ISO string
    const formattedDate = utcDate.toISOString();

    return formattedDate;
  }
}

export const playSound = () => {
  const audio = new Audio("https://support.pixerfect.com/arpeggio-467.mp3");
  audio.play().catch((error) => {
    console.error("Audio playback failed:", error);
  });
};

export const showNotification = (title, options, url) => {
  const isIosDevice =
    /iPhone|iPad|iPod/.test(navigator.userAgent) && !window.MSStream;
  if (isIosDevice) {
    // Custom in-app notification logic for iOS devices
    console.log("iOS device detected; using in-app notifications instead.");
  } else {
    if (Notification.permission === "granted" && !isIosDevice) {
      const notification = new Notification(title, options);
      notification.onclick = () => {
        window.open(url); // Open the URL in a new tab
      };
      playSound(); // Play sound when notification is shown
    } else {
      console.error("Notification permission not granted");
    }
  }
};
